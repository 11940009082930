<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="my-3">
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 font-weight-bold">
        {{ "Tasks" }}
      </div>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" to="/tasks/import_policy_payments"
        ><v-icon left>mdi-cloud-upload</v-icon> Import</v-btn
      >
      <v-btn depressed class="mx-2" color="primary" to="/tasks/backups"
        ><v-icon left>mdi-folder-upload</v-icon> Backups</v-btn
      >
      <v-btn depressed color="warning" to="/tasks/logs"
        ><v-icon left>mdi-clipboard-alert</v-icon> Logs</v-btn
      >
    </div>
    <v-card class="pa-1">
      <div class="d-flex justify-end my-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon @click="getTask"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
          </template>
          <span>Reload</span>
        </v-tooltip>
      </div>
      <template>
        <v-data-table
          :loading="loading"
          :options.sync="options"
          :headers="headers"
          :footer-props="footerProps"
          single-expand
          show-expand
          item-key="uuid"
          :expanded.sync="expanded"
          :items="tasks"
          :server-items-length="totalTasks"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ getDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.taskType`]="{ item }">
            {{ getType(item.taskType) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <template v-if="item.setting">
                  <div class="d-flex justify-center">Settings:</div>
                  <v-divider class="my-2"></v-divider>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="4"
                      v-for="(ite, i) in Object.entries(item.setting)"
                      :key="i"
                    >
                      <strong
                        >{{
                          item.taskType == "INSERT_PAYMENTS"
                            ? getNamesP(ite.toString().split(",")[0])
                            : getNames(ite.toString().split(",")[0])
                        }}: </strong
                      >{{ getDataShow(ite) }}
                    </v-col>
                  </v-row>
                  <v-divider class="mb-2"></v-divider>
                </template>
              </v-container></td
          ></template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
import { capitalize, upperCase } from "lodash";
import { mapActions, mapState } from "vuex";
export default {
  name: "task-payments-policies",
  mixins: [utilMixin],
  data() {
    return {
      loading: false,
      tasks: [],
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500],
      },
      states: [],
      expanded: [],
      totalTasks: 0,
      options: {},
      req: {
        limit: 100,
        offset: 0,
      },
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "taskType",
        },
        /*  {
          text: "Length",
          align: "center",
          sortable: false,
          value: "lenght",
        },
        {
          text: "Last Line",
          align: "center",
          sortable: false,
          value: "lastLine",
        }, */
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Create Date",
          align: "center",
          sortable: false,
          value: "createdAt",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
  },
  watch: {
    options: {
      handler() {
        this.getTask();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    getTask() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.req = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.loading = true;
      getAPI
        .post("/task-import/list-tasks", this.req)
        .then((res) => {
          this.loading = false;
          this.tasks = res.data.result;
          this.totalTasks = res.data.count;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getType(type) {
      let t = type;
      if (t == "UPDATE_POLIZES") {
        t = "UPDATE_POLICIES";
      }

      if (t == "UPDATE_DELINCUENTS") {
        t = "UPDATE_DELINQUENTS";
      }

      t = t.replaceAll("_", " ");

      return upperCase(t);
    },

    getNames(value) {
      if (value == null || value == "" || value == undefined) {
        return "-";
      }
      switch (value) {
        case "companyId":
          return "Business Type";
        case "dob":
          return "DOB";
        case "polizeNumber":
          return "Polize Number";
        case "paymentAmount":
          return "Payment Amount";
        case "statusForMachetId":
          return "Status for Matched";
        case "statusNoForMachetId":
          return "Status for not Matched";
        case "lastName":
          return "Last Name";
        case "name":
          return "Name";
        case "members":
          return "Members";
        case "month":
          return "Month";
        case "year":
          return "Year";
        case "actualMonth":
          return "Current Month";

        default:
          return "-";
      }
    },
    getNamesP(value) {
      if (value == null || value == "" || value == undefined) {
        return "-";
      }
      switch (value) {
        case "companyId":
          return "Business Type";
        case "dob":
          return "Payment Date";
        case "paymentAmount":
          return "Payment Amount";
        case "polizeNumber":
          return "Polize Number";
        case "statusForMachetId":
          return "Status for Matched";
        case "statusNoForMachetId":
          return "Status for not Matched";
        case "lastName":
          return "Last Name";
        case "name":
          return "Name";
        case "members":
          return "Members";
        case "month":
          return "Month";
        case "year":
          return "Year";
        case "actualMonth":
          return "Current Month";

        default:
          return "-";
      }
    },

    getDataShow(value) {
      const key = value.toString().split(",")[0];
      const valkey = value.toString().split(",")[1];

      if (key == "companyId") {
        if (
          this.companies.length == 0 ||
          this.companies == null ||
          this.companies == undefined
        ) {
          return "-";
        }
        const x = this.companies.find((c) => c.id == valkey);
        return x ? x.name : "-";
      }
      if (key == "statusForMachetId" || key == "statusNoForMachetId") {
        if (this.states.length == 0) {
          return "-";
        }
        const x = this.states.find((c) => c.id == valkey);
        return x ? x.name : "-";
      }
      return valkey;
    },

    getStates() {
      this.loading = true;
      getAPI(`/client-status-nom/list`)
        .then((res) => {
          this.states = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  async mounted() {
    await this.actListCompaniesNom();
    await this.getStates();
  },
};
</script>
<style lang=""></style>
